@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  src: local('Montserrat Thin'), url('/fonts/Montserrat-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local('Montserrat Light'), url('/fonts/Montserrat-Light.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), url('/fonts/Montserrat-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Regular'), url('/fonts/Montserrat-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat Medium'), url('/fonts/Montserrat-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local('Montserrat Semibold'), url('/fonts/Montserrat-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), url('/fonts/Montserrat-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: local('Montserrat Extrabold'), url('/fonts/Montserrat-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 100;
  src: local('Proxima Nova Thin'), url('/fonts/ProximaNovaT-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova Light';
  font-style: normal;
  font-weight: 300;
  src: local('Proxima Nova Light'), url('/fonts/ProximaNova-Light.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  src: local('Proxima Nova Black'), url('/fonts/ProximaNova-Black.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 500;
  src: local('Proxima Nova Regular'), url('/fonts/ProximaNova-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: normal;
  src: local('Proxima Nova Regular'), url('/fonts/ProximaNova-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  src: local('Proxima Nova Semibold'), url('/fonts/ProximaNova-Semibold.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  src: local('Proxima Nova Bold'), url('/fonts/ProximaNova-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 900;
  src: local('Proxima Nova Extrabold'), url('/fonts/ProximaNova-Extrabld.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local('Poppins Regular'), url('/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500 ;
  src: local('Poppins Medium'), url('/fonts//Poppins-Medium.ttf') format('truetype');
}